/* styles.css */

.flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* min-height: 90vh; */
    background-color: #f0f4f8; /* Use the desired background color */
    /* padding: 1.5rem; */
  }
  
  .card {
    display: flex;
    justify-content: center;
    background-color: #ffffff; /* Use the desired background color */
    border-radius: 0.5rem;
    min-height: 70vh;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .check-icon {
    width: 6rem;
    height: 6rem;
    color: #34d399; /* Use the desired color */
    margin: 0 auto;
  }
  
  .title {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: #4b5563; /* Use the desired text color */
  }
  
  .message {
    margin-top: 0.75rem;
    font-size: 1.125rem;
    text-align: center;
    color: #6b7280; /* Use the desired text color */
  }
  
  .link {
    margin-top: 1.5rem;
    color: #3b82f6; /* Use the desired link color */
  }
  