.invoice-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .invoice-content {
    padding: 20px;
    border: 1px solid #ccc;
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .invoice-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .item-label {
    font-weight: bold;
  }
  .item-value {
    color: #555; /* Adjust color as per your design */
  }