/*========Login========*/
.loginForm ul li{ list-style-type: none;}
.loginForm ul{ display: flex; width: 100%; margin-bottom: 0;}
.loginForm ul li { width: 50%; text-align: center; font-weight: 400; cursor: pointer; font-size: 20px; padding: 16px 20px; border-bottom: 2px solid #cdcdcd;}
.loginForm ul li.signBtn-active{ color: #9e6dc8; border-bottom: 2px solid #9e6dc8;}
.loginTabArea{     height: 310px;   overflow-x: auto; padding: 16px;}
.loginTabContent{ display: none;}
.loginTabContent.loginTabContentActive{ display: block;}
.loginTabArea h2{ text-align: center; padding: 16px 0 32px 0; font-weight: 300;     line-height: 36px;
    font-size: 22px;}
.loginTabArea .btn{ border-radius: 10px;   position: relative;  text-align: center;   width: 100%; color: #fff;    min-height: 52px;   font-size: 18px;  margin-bottom: 16px; display: flex;
     align-items: center; background-blend-mode: color;  justify-content: center;}
.loginTabArea .btn-primary{ color: #000;}
.loginTabArea .btn-apple{ background: #000; box-shadow: 2px 2px 5px #0000003b;}
.loginTabArea .btn-facebook{ background: #3779eb; box-shadow: 2px 2px 5px #3779eb30;}
.loginTabArea .btn-phone{ background: #9e6dc8; box-shadow: 2px 2px 5px #9e6dc829;}
.loginButtonGroup .btn-google{ background: #f7f7f7; color: #707070; box-shadow: 2px 2px 5px #00000029;}
.loginTabArea .btn img{ position: absolute; left: 30px; width: 25px;}

.loginWithEmail{ margin: 56px 0 0px 0;}
.orLogin{ border-top:1px solid #d8d8d8; position: relative; max-width: 60%; margin: 0 auto; }
.orLogin + h3{ text-align: center; padding-bottom: 16px;     font-size: 20px; opacity: .8;} 
.loginWithEmail p a{ font-weight: bold;}
.orLogin h3{position: absolute; top: -12px;  left: 0;  right: 0;  margin: 0 auto;
    background: #fff;  width: 40px;}
.signupArea .orLogin h3{ width: 40px;}
.form-input-border{ border-radius: 0; padding:12px 0 ;font-size: 1rem; border:0; border-bottom: 1px solid #cfcfcf !important ; }    

.loginfooter{ background-color: #f3edf8; padding: 16px; text-align: left; border-radius: 0 0px 8px 8px;}
.loginfooter img{ width: 25px;}
.loginfooter a{ text-transform: uppercase; font-weight: 600;}
.loginfooter h3 span{ background: #9e6dc8; border-radius: 50%; padding: 10px; width: 30px;
    height: 30px;  text-align: center;  color: #fff;
      line-height: 15px;}
.accountDetails ul li img{ width: 26px; opacity: .3;}
.mapContent .inputdiv .form-select{border-radius: 8px; min-height: 45px !important;}
.mapToggle{    height: 370px;  overflow-y: auto;  overflow-x: hidden;}
.profileImage{ position: relative; display: table;   margin: 0 auto;}
.userInfo .profileImage img{ width: 100px; height: 100px; border:2px solid #be7dfa }
.file-upload {  height: 40px; width: 40px; border-radius: 100px;  position: absolute; display: flex;
    justify-content: center;  align-items: center;  background: #be7dfa; overflow: hidden;  background-size: 100% 200%;
    transition: all 1s;  color: #fff;  font-size: 25px;  top: -5px;  right: -10px;}
.dashboardCard .rounded-full img { border-radius: 0;  height: auto;}
.rounded-full img { border-radius: 50%;  height: 100%;   object-fit: cover;}
.file-upload input[type='file'] {  height: 40px;  width: 40px;  position: absolute; top: 0; left: 0;  opacity: 0;  cursor: pointer;}
.file-upload i{ font-size: 16px;}

.myFavpros li span img{ width: 70px; height: 70px; border-radius: 50%;  object-fit: cover;}
.myFavpros li span{ font-weight: 500; font-size: 18px;}
.heartChecked{ position: relative; margin-bottom: 0; line-height: 1;}
.heartChecked i{  color:#432375 !important ;}
.heartChecked input[type=radio] { -webkit-appearance: none;  position: absolute;  width: 100%;
    height: 100%;  top: 0;  left: 0;  cursor: pointer;}
.heartChecked input[type=radio]:checked + i{ color: #9e9e9e !important;}
.myFavpros .favsetting{ background-color: #43237524;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    font-size: 30px;
    color: #432375;
    margin: 30px;
    float: right;
    cursor: pointer;
}

.accountSettings ul{ clear: both;}
.checkicon i img{ filter: invert();}
.btn-check:active+.btn-secondary,.btn-check:checked+.btn-secondary,.btn-secondary.active,.btn-secondary:active,.show>.btn-secondary.dropdown-toggle{
    color:#fff;   background-color:#fcd500;   border-color:#fcd500}


#trackPro .modal-body{    height: 540px; overflow-y: auto;}
.trackPoContent{ background: #fff; padding: 24px 16px 0 16px;  width: 100%; border-radius: 0 0px 10px 10px;}
.progressbar { background-color: #432375; padding: 2px; }
.trackProList ul li {  border-bottom: 1px solid #cdcdcd; display: flex; align-items: center;  justify-content: space-between;
    font-weight: 300; padding: 15px 0;}
.trackProList ul li p{ margin-bottom: 0;}    
.trackProList ul li a{ width: 100%;}
.trackPoContent h2{ font-size: 22px;}
#startfrom p{ text-align: center;}
.popupNoheader .btn-close{ position: absolute; right: 8px; top: 8px; z-index: 222;}
#startfrom img{    border-radius: 8px 8px 0 0;}

.sharSec img{ max-width: 50px; display: block; margin: 0 auto;}
.sharSec a{ font-size: 18px;}

.rightClubList{    padding: 24px; box-shadow: 2px 0px 11px #0000003d;  margin: 0 0 24px 0;
    border-radius: 8px; width: 100%;}
.rightClubList ul{ margin-bottom: 0;}    
.rightClubList ul li{ margin-bottom: 24px; display: flex;  align-items: center;}
.rightClubList ul li:last-child{ margin-bottom: 0;}
.rightClubList ul li img{ margin-right: 16px;}

.popupFooter{ border-top: 1px solid #cdcdcd;}
.popupFooter .checkboxItem .form-check label{ line-height: 1.5;}
.clubScroll{height: 410px;overflow-y: auto;}



/*Service*/
.serviceMain{ position: relative;}
.max1060{ max-width: 1030px;}
.serviceSearch .form-control{  min-height: 70px;  border-radius: 50px;  padding-left: 60px; font-size: 18px;}
.announcementArea{ width: 100%; padding: 10px 10px; background-color: #9e6dc8;}
.announcementArea p{ color: #fff; font-size: 20px; margin-bottom: 0; text-align: center;}
.announcementArea .announcementIcon{ position: absolute; left: 0; cursor: pointer;} 
.announcementArea .container{ position: relative;}
.serviceSearch{ position: relative;}
.serviceSearch span{ position: absolute; left: 25px;  top: 22px; font-size: 25px; opacity: .5;}
.serviceReminder span{ font-size: 16px;}
.serviceSliderPage .owl-item img{ height: 320px; border-radius: 32px; object-fit: cover;}
.sliderContent{ position: absolute; top: 100px; left: 40px;}
.servicepageFooter{ padding: 10px 0; border-top: 1px solid #9e9e9e; position: fixed; z-index: 111; background: #fff; bottom: 0 ; width: 100%;} 
.serviceSliderPage .sliderContent h2{ color: #fff;}
.serviceSliderPage .sliderContent p{ color: #fff; margin-bottom: 40px;}
.serviceItemservice{ margin-bottom: 32px;}
.serviceItemservice img{ max-width: 100%;}
.servicepageFooter ul { display: flex; align-items: center ; justify-content: space-between; margin-bottom: 0;}
.servicepageFooter ul li { list-style-type: none; line-height: 1;}
.servicepageFooter ul li img{ display: block; margin: 0 auto 8px; width: 30px;}
.servicepageFooter ul li .navImgActive{ display: none;}
.servicepageFooter ul li {cursor: pointer;}
.servicepageFooter ul li span{ color: #9e9e9e; font-weight: 400; font-size: 18px;}
.servicepageFooter ul li.serviceNavLink-active img{ display: none;}
.servicepageFooter ul li.serviceNavLink-active .navImgActive{ display: block;}
.servicepageFooter ul li.serviceNavLink-active span{color: #9e6dc8}
.needSomething{ margin-bottom: 100px;}
.needSomething ul{ display: flex; align-items: center; justify-content: center;}
.needSomething ul li a{ font-size: 24px; font-weight: bold; color: #9e6dc8;}
.needSomething ul li{ list-style-type: none; margin: 0 30px;}
.needSomething ul li img{ margin-right: 5px;}
.servicePagetabContent{ display: none;}
.servicePageTabActive.servicePageTabActive{ display: block;}

.announcementsModal img { max-width: 50%;}
.serviceReminder{ display: none; justify-content: space-between ; align-items: center;  padding: 32px;
    border-radius: 8px;  box-shadow: 1px 3px 6px #00000047; margin-top: 40px; }
.serviceReminder.show{ display: flex;}
.serviceReminder p{ margin-bottom: 0; margin-top: 6px;}
.serviceReminder .fa-close{ width: 40px; min-width: 40px; height: 40px; line-height: 40px; background-color: #9e6dc8; border-radius: 50%;
    color: #fff; font-size: 25px; text-align: center; cursor: pointer;}
    .serviceReminder .fa-angle-right{ color: #9e6dc8; font-size: 60px;}
.serviceCatdiv { position: relative; }
.serviceCatdiv img{ height: 400px; object-fit: cover; width: 100%; border-radius:  8px; }
.serviceCatdiv h3{ position: absolute; bottom: 0; background-color: #9e6dc8; border-radius: 0 0 8px 8px;
    color: #fff; font-size: 18px; width: 100%; padding: 15px 30px; font-size: 22px;} 
.ServiceByCategorie span{ font-size: 64px; margin-bottom: 24px;}
.serviceSliderPage .owl-stage{padding-left:0px !important;}

.taketourPopup ul{ display: flex; justify-content: center; margin-top: 40px; margin-bottom: 0;}
.taketourPopup{ padding: 50px 0 20px 0;}
.taketourPopup h1{ line-height: 1.2; margin-top: 24px;}
.taketourPopup ul li{ list-style-type: none; margin: 0 5px; }
.taketourPopup ul li span{ width: 18px; height: 18px; cursor: pointer; border-radius: 50%; background-color: #333; display: block;}
.taketourPopup ul li span.active{ background: #432375;}
.taketourPopup{ height:550px ; overflow-y: auto;}
.taketourPopup p{ padding: 0 30px;}
.complementsec .btn-fav{ color: #fff;}
.copyCode button {width:100%; padding: 10px 20px; border:1px dashed #cdcdcd; border-radius: 8px; font-size: 24px; font-weight: 600; text-align: center; margin: 0px auto 50px;}
.enterAmount{ cursor: pointer;}

.howItworks{ padding: 32px;}
.howItworks h2{ font-size: 22px;}
.trackInviteArea{ padding: 32px;}
.trackInvites{ margin-bottom: 40px;}
.trackInviteArea h2{ font-size: 22px; margin-bottom: 16px; display: flex; justify-content: space-between;}
.trackInviteArea h2 span{ color:#3d1f6c}
.modal-body .modal-body{ padding: 0;}
.buttonSec .dropdown-item:active{background-color: rgba(255, 0, 0, 0) !important;}
.buttonSec .dropdown-item:hover{ background-color: rgba(255, 0, 0, 0) !important; opacity: .8;}
.addPhotosSec{ flex-wrap: wrap;}
.addPhotosSec .container { padding: 0;}
.addPhotoImg{ position: relative; width: 60px;}
.addPhotoImg input{    position: absolute; height: 60px; opacity: 0; cursor: pointer;}
.anticon-eye{ display: none;}
.ant-upload-list{ display: flex;  width: 100%; flex-wrap: wrap; }
.ant-upload-list-picture-card-container{ width: 70px; text-align: center; position: relative; margin: 0 5px;}
.ant-upload-list-picture-card-container img{width: 70px; border: 1px solid #cdcdcd; height:60px !important; object-fit: cover; margin-bottom: 10px;}

.ant-upload-list-item-card-actions-btn{ border: 0;}
.ant-upload-list-item-card-actions-btn{ 
    position: absolute;
    top: -8px;
    right: -5px;
    background: #f61107;
    padding: 5px;
    color: #fff;
    width: 27px;
    line-height: 0;
    border-radius: 50%;
    height: 27px;}
.ant-upload-list-item-name{ display: none;}
.ant-tooltip-inner{ display: none;}

.tabContent{ display: none;}
.tabContent.show{ display: block;}
.customerTabArea h4{ padding:8px 20px 16px; }
h4 {  font-size: 1.3rem;}
.customerTabArea ul li { display: flex; position: relative; justify-content: space-between; padding:12px 20px 12px 36px; border-bottom: 1px solid #cdcdcd;}
.customerTabArea ul li p{ margin-bottom: 0; margin-top: 8px;}
.customerTabArea ul li:before{ position: absolute; left: 20px;  content: '';  width: 6px;  height: 6px;  background-color: #9e9e9e;
    top: 20px;  border-radius: 50%;}
.customerTabArea ul li:last-child{border-bottom: 0;}    
.customerTabArea span{ font-size: 20px; margin-top: 6px; color: #432375; }
.customerTabArea h5 { font-size: 16px; font-weight: normal;   color: #51565e}
.customerTabArea li div:nth-child(2){ text-align: right;}

.addMoreService { max-height: 400px; overflow-y: auto;}
#addService .actionButton{ position: initial !important;     margin: 0;   padding: 24px;}
#addService .btn{ width: auto;}
.finishMyBooking img{ border-radius: 8px 8px 0 0;}
.addPhotosSec img{ height: 35px;}

.translated-rtl .annoouncments .appoitmentButton button.tabBtn.me-4{ margin-right: 0 !important; margin-left: 1.5rem!important;}
.translated-rtl .customerTabArea ul li{ padding: 12px 36px 12px 24px;}
.translated-rtl .customerTabArea ul li:before{ right: 20px; left: auto;}
.translated-rtl .changeEye i{ left: 20px;  top: 48px; right: auto;}
.translated-rtl .customerTabArea li div:nth-child(2) {  text-align: left;}
.translated-rtl .loginTabArea .btn img{ left: auto; right: 30px;}
.translated-rtl .loginfooter{ text-align: right;}
.translated-rtl .accountDetails ul li img{ margin-left: 1rem!important; margin-right: 0 !important;}
.translated-rtl .whorighthands ul{ padding: 0 16px 0 0;}
.translated-rtl .whorighthands .heading{ text-align: right;}
.translated-rtl .experienceMattersContent{     margin-right: 430px;}
.memberShipBanner .memberBnrContent{ text-align: left;}
.translated-rtl .swiper{ direction: ltr;}
.translated-rtl .swiper-wrapper{ direction: ltr;}
.translated-rtl .freeService .list img{ margin-right: 0 !important; margin-left: 16px !important;}
.translated-rtl .rightClubList ul li img{margin-right: 0 !important; margin-left: 16px !important; }

.translated-rtl .announcementArea .announcementIcon{ left: auto; right: 0;}
.translated-rtl .announcementArea .announcementIcon img{    transform: scaleX(-1);}
.translated-rtl .announcementArea p .me-3 {  margin-left: 1rem!important;}
.translated-rtl .serviceSearch .form-control{ padding-left: 0; padding-right: 60px;}
.translated-rtl .serviceSearch span{ left: auto; right: 25px;}
.translated-rtl .serviceSearchArea .heading{ text-align: right !important; }
.translated-rtl .serviceReminder .fa-angle-right{     transform: rotate(180deg);}
.translated-rtl .ServiceByCategorie span{ transform: rotate(180deg); }
.translated-rtl .serviceSliderPage .sliderContent{ left: auto; right: 40px; text-align: right;}
.translated-rtl .needSomething ul li img { margin-right: 0;  margin-left: 10px;}


@media (max-width:1100px){

    .serviceCatdiv img { height: 300px;}
    .SliderbgImage { height: 320px;}
   }
@media (max-width:991px){

 .serviceCatdiv img { height: 300px;}
 .SliderbgImage { height: 300px;}
}
@media (max-width:767px){

.serviceItemservice {  margin-bottom: 16px;}
.serviceSliderPage{ margin-top: 24px;}
.ServiceByCategorie{ padding: 0 0 16px 0 !important;}
.serviceCatdiv img { height: 260px;}
.loginTabArea .btn{ font-size: 16px;}
.loginTabArea .btn img{ left: 20px;}


.SliderbgImage { height: 230px;}
}

@media (max-width:414px){
    .announcementArea .announcementIcon img{ width: 30px;}
    .announcementArea img{ width: 25px;}
    .announcementArea p{ font-size: 16px;}
    .serviceSearch .form-control{ min-height: 60px; font-size: 16px;}
    .serviceSearch span{ top: 16px;}
    .serviceReminder{ padding: 16px; margin-top: 24px;}
    .serviceReminder img{ max-width: 25px;}
    .serviceSliderPage{ margin-top: 0;}
    .serviceSliderPage .owl-item img {  height: 250px; border-radius:24px}
    .serviceSliderPage .sliderContent p{ margin-bottom: 24px;}
    .sliderContent{    top: 50px;   left: 30px;}
    .serviceItemservice {  margin-bottom: 16px;}
    .needSomething h2{ font-size: 18px;}
    .needSomething ul li{ margin: 0 15px;}
    .needSomething ul li a{ font-size: 18px;}
    .servicepageFooter ul li img{ width: 25px;}
    .servicepageFooter ul li span{ font-size: 14px;}       
    .serviceCatdiv img { height: 240px;}
    .serviceSlider {     padding: 10px;   }
    .serviceBanner .serviceSlider .btn{ bottom: 10px;}
    .customerTabArea ul li{flex-wrap: wrap;}
    .customerTabArea li div:nth-child(2) {   text-align: left;  width: 100%; padding: 5px 0 0 0; }
    .customerTabArea h4{font-size: 1.1rem;}
    .customerTabArea h3 { font-size: 16px !important;}
    .myFavpros .favsetting{ margin: 16px;}
    .SliderbgImage { height: 180px;}
}  
@media (max-width:360px){
   
    .loginTabArea .btn{ font-size: 14px;}
    .loginTabArea .btn img{ left: 15px; width: 20px;}
    }


/*language*/


.translated-rtl .myFavpros li span img{    margin-right: 0 !important;  margin-left: 1.5rem;}
.translated-rtl .list li .heartChecked i { transform: rotate(0deg);}